import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { sk, en } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, GetLanguage } from './items.js';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { reference, coworking } from './reference_list.js';

export const Reference = (props) => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState(LoadLanguage());
    const [list, setList] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/app/logo.png');

    var lang = GetLanguage(LoadLanguage());
    let { func } = props;

    useEffect(() => {
        func(3);

        GoHome();

        return () => {
        };

    }, []);



    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>


            <div style={{ ...styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }}>
                <h1 style={{ ...styles.TextXXLabel, marginTop: 10, textAlign: 'left' }}>{lang.reference.toUpperCase()}</h1>
                <Line />
            </div>

            <div style={{ ...styles.BlockMaxLarge, marginTop: 0, marginBottom: 50 }}>
                {reference.list.map((item, index) => {
                    return (
                        <p key={index} style={{ ...styles.TextSmall, marginBottom: 10 }}>{item}</p>
                    )
                })}
            </div>

            <div style={{ ...styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }}>
                <h1 style={{ ...styles.TextXXLabel, marginTop: 10, textAlign: 'left' }}>{lang.coworking}</h1>
                <Line />
            </div>

            <div style={{ ...styles.BlockMaxLarge, marginTop: 0, marginBottom: 50 }}>
                {coworking.list.map((item, index) => {
                    return (
                        <p key={index} style={{ ...styles.TextSmall, marginBottom: 10 }}>{item}</p>
                    )
                })}
            </div>

        </div >
    )
}