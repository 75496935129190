export const reference = {
    list: [
        'Autosalóny: MAZDA - Nitra, B. Bystrica, SEAT - Nitra,B.Bystrica, Zvolen',
        'RENAULT ISTROS- Bratislava, ŠKODA - Bratislava',
        'CITROEN - B. Bystrica,Nitra, AUTO CITY - Nitra, TOYOTA - Nitra, KIA-Prievidza, Mercedes NR r.2020',
        'AKVAMARIN hotel Besenova 8/2021',
        'ALCAN - Levice',
        'ALLIANZ Slovenská poisťovňa - Zlaté Moravce, Nitra',
        'ALLTOYS - Prievidza',
        'A.R.S. - Bratislava',
        'ASKO - Nitra',
        'Avidol 2-Bratislava',
        'BAUMAX - Prešov',
        'BBC Five -Bratislava r.2010',
        'BILLA - Slovensko',
        'BK Centrum - Bratislava',
        'BOWLING - Zlaté Moravce',
        'BUSINESS CENTER NITRA,Trnava',
        'Budyšínska Rezidencia,BA r.2020',
        'Bytové apartmánové domy Oščadnica',
        'Byty - Podkolibska - Bratislava',
        'Bytové domy Kulháň - Záhorská Bystrica',
        'CASSOVAR COMPLEX - Košice r.2010',
        'CESAM-Vráble',
        'Coop Jednota - Hanušovce,Zlate Moravce',
        'CITY PARK, Levice,Trnava,Rovinka',
        'ČS Slovnaft - Slovensko',
        'Čeresne 2-Bratislava 4/2021',
        'DANTER - Dunajská Streda',
        'DEMOS Nitra 7/2021',
        'DIGITAL PARK - Bratislava',
        'ECO POINT 2-Kosice r.2018',
        'Europa Business Center - Banská Bystrica',
        'EUROPA SHOPPING CENTER - Banská Bystrica',
        'GALANDIA - Galanta',
        'Hotel MINCIAR - Skalka',
        'HOTEL SANDOR - Piešťany',
        'Hotel Tenis - Zvolen',
        'Hotel Kaskády-Sliač',
        'Hotel Zlatý Dukát - Košice',
        'HOTEL SLADOVNA-Černá Hora',
        'Hotel HORIZONT-Stara Lesna r.11/2015',
        'Hrad Bratislava  5/2021',
        'Hypernova, Albert - Slovensko',
        'Hypermarket Tesco Galéria - Trnava, Nitra, Bratislava-Lamač',
        'IVECO -Žilina',
        'Interpolis BB 6/2022',
        'JASPOL - Partizánske',
        'JOPA UNIKER - Banská  Bystrica',
        'KAUFLAND - Slovensko',
        'KIA ŽILINA',
        'KLIMAK -Nitra',
        'Klingerka,BA 3/2021 - 4/2022',
        'K&J&G -Vajnory r.2015',
        'KSNC - Michalovce',
        'Lakeside park 2  BA 6/2022',
        'Letisko M.R.Štefanika BA-r.2016-2017',
        'Logistické centrum - Nové Mesto nad Váhom',
        'L-Palece - Žilina',
        'LÍDL - Čadca',
        'Lyžiarske stredisko Krahule',
        'MARKET NOVIJ - Ukrajina',
        'MAX - Dunajská Streda',
        'Medicínske centrum - Prievidza',
        'Metrostav SK',
        'Metro - Košice, Nitra',
        'Mercedes-Benz,Motor Car Košice-r.6/2017',
        'MEVIS -Šamorín r.2015',
        'MILSY - Bánovce nad Bebravou',
        'MLYNY Nitra r.2009',
        'MONKAS - Nitra',
        'MOTEL Skalka',
        'Národný žrebčín Topoľčianky',
        'Nový urgent Levice 7/2021',
        'Nová terasa-Košice r.2016-2018',
        'Nova Cvernovka BA 6/2022',
        'OC POINT - BB,r.2018',
        'OC Tesco-Urban Košice 3/2021',
        'OC - Rožňava, Bardejov, Michalovce, BA-Rača,Prešov',
        'OC  Galéria-Košice',
        'OD - Zámčan',
        'OSRAM - Nové Zámky',
        'OPTIMA- Košice',
        'OBCHODNE CENTRUM A HOTEL CENTRAL-Bratislava r.2012-2013',
        'OBCHODNE CENTRUM GALERIA-Lučenec r.11/2015',
        'OC SARATOV-Bratislava r.6/2020',
        'ORANGE SLOVENSKO',
        'ORBIS Nitra  2021',
        'PARK SNOW DONOVALY',
        'PANORAMA CITY 1,2,3-BA r.3/2016-2017,2018',
        'Plaváreň-Žilina  4/2021',
        'Pošta - B. Bystrica',
        'Potraviny FRESH  r.2020,2021',
        'RIVER PARK-Bratislava r.2010',
        'Rezidencia pri Radnici-Košice r.2018',
        'RETAIL PARK - Ružomberok',
        'RETAIL PARK - Lubotice r.4/2020',
        'RELAX Centrum-Uherské Hradište',
        'Riadieľstvo hraničnej polície - Sobrance',
        'SAMSUNG - Galanta',
        'SLSP - Slovensko',
        'Slovenská národná galéria - Bratislava 11/2022',
        'SsÚSCH - Banská Bystrica',
        'SUPERMARKET- Poprad',
        'STRABAG koncernová centrála Slovensko',
        'STOP SHOP-Dubnica',
        'TABAN-Nitra 5/2021',
        'TATRA BANKA-Poprad r.2020',
        'TESCO - Slovensko',
        'TIGERLAK SLOVAKIA - Pezinok',
        'TOWER 115 - Bratislava',
        'ÚPSVR - Rimavská Sobota',
        'UNIVERZITNY VEDECKY PARK  KOMENSKEHO UNIVERZITY-BA r.2015',
        'VISTEON PRIEMYSELNÝ PARK- Nitra, Dubnica nad Váhom',
        'VÚB - Košice, Dudince,',
        'Vajnorska 21,BA r.2020',
        'WESTED TOWER-Bratislava r.2011',
        'WESTEND SQUARE-Bratislava r.8/2011',
        'WESTEND QUADRANT-Bratislava r.2014',
        'WESTEND CROSSING-Bratislava r.2019',
        'VW-Bratislava r.2014',
        'YELL POINT-Ruzomberok r.5/2020',
        'Zimný štadión - Košice',
        'Zuckermandel-Bratislava r.2017',
        'ZŠ - Fiľakovo',
        'ŽST - Piešťany, Palárikovo, Galanta',
    ]
}

export const coworking = {
    list: [
        'J & T',
        'Billa',
        'Kaufland',
        'Tesco',
        'Lidl',
        'Takenaka',
        'Spedos',
        'Metrostav SK, CZ',
        'Chemkostav',
        'Strabag',
        'Betpres',
        'Hornex',
        'Ise',
        'Sytiq',
        'Colas',
        'Montin',
        'Dag Slovakia',
        'L - Construction',
        'Kami Profit',
        'Dynamik',
        'Bauska',
        'Stabil',
        'Vod eko',
        'Roko gips',
        'InVest Sala',
        'Keraming',
        'Assyx',
        'Inpek',
        'Skanska',
    ]
}
