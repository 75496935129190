import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk, en, hu } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faShoppingCart, faSearch, faAngleDoubleLeft, faChevronLeft, faAngleDoubleRight, faChevronRight, faBars, faCross, faTimesCircle, faList, faAngleDown, faAnglesUp, faAngleLeft, faGear, faCaretUp, faArrowRight, faAngleRight, faPerson, faUser, faPenToSquare, faClose, faRightFromBracket, faEdit, faArrowRightToBracket, faHome, faPaperPlane, faPhone, faCheck, faXmark, faHeart } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faMobile, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Drawer, Slide } from '@mui/material';
//import Slider from "react-slick";
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';

export const Init = () => {
    localStorage.removeItem('settings');
}

export const GetLanguage = (lang) => {
    if (lang == 0) {
        return sk;
    }
    if (lang == 1) {
        return en;
    }
    if (lang == 2) {
        return hu;
    }
    return sk;
}

export const SaveLanguage = (lang) => {
    localStorage.setItem('language', lang);
}


export const LoadLanguage = () => {
    var lang = localStorage.getItem('language');
    if (lang != null) {
        return lang;
    } else {
        return 0;
    }
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (true)
    }
    return (false)
};


export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {                
            }

    */
    const [language, setLanguage] = useState(LoadLanguage());

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();

    const flag_sk = require('./react/app/flag_sk.png');
    const flag_en = require('./react/app/flag_en.png');
    const flag_hu = require('./react/app/flag_hu.png');

    const menuHeight = 60;
    const menuHeightSmall = 40;

    let { func } = props;
    var lang = GetLanguage(LoadLanguage());

    useEffect(() => {
        var l = LoadLanguage();
        setLanguage(l);

        return () => {
        };

    }, []);

    const Press = (type, value) => {
        func(type, value);
    }

    const ChangeLang = (id) => {
        setLanguage(id);
        SaveLanguage(id);
        navigate('/');
        window.location.reload();
    }

    return (
        <div style={{ ...styles.BlockRow, height: isSmall ? menuHeightSmall : menuHeight, backgroundColor: global.theme_black, alignItems: 'center' }}>
            <div style={{ ...styles.BlockLeft, paddingLeft: isSmall ? global.padding_small : global.padding }}>
                <div style={{ ...styles.BlockRow }}>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextNormal, color: global.theme_white, fontWeight: 'lighter', marginRight: 10 }}>{lang.call_us}:</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextNormal, color: global.theme_white, fontWeight: 'normal' }}>{language == 2 ? global.company.mobil_hu : global.company.call}</p>
                </div>
            </div>
            <div style={{ ...styles.BlockRow, width: 150, paddingRight: isSmall ? global.padding_small : global.padding, justifyContent: 'flex-end' }}>
                <IconButton style={{ marginRight: 10 }}>
                    <img onClick={() => ChangeLang(0)} src={flag_sk} style={{ width: 30, height: 30, opacity: language == 0 ? 1.0 : 0.3 }}></img>
                </IconButton>
                <IconButton style={{ marginRight: 10 }}>
                    <img onClick={() => ChangeLang(1)} src={flag_en} style={{ width: 30, height: 30, opacity: language == 1 ? 1.0 : 0.3 }}></img>
                </IconButton>
                <IconButton>
                    <img onClick={() => ChangeLang(2)} src={flag_hu} style={{ width: 30, height: 30, opacity: language == 2 ? 1.0 : 0.3 }}></img>
                </IconButton>
            </div>
        </div>
    )

};


export const MenuLogo = (props) => {
    /*
            

            USAGE:
            <MenuLogo func={MenuLogoPress.bind(this)} />

            const MenuLogoPress = (value) => {            
            }

    */
    const [label, setLabel] = React.useState('');
    const [isOpen, setOpen] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = props.lang;

    const navigate = useNavigate();
    const location = useLocation();

    const menuHeight = 140;
    const bodyHeight = 600 - menuHeight;

    const menuHeightSmall = 100;
    const bodyHeightSmall = 400 - menuHeight;

    useEffect(() => {
        return () => {
        };

    }, []);

    const Press = (type, value) => {
        func(type, value);
    }

    const logo = require('./react/app/logo.png');
    const banner = require('./react/app/banner.jpg');
    const banner_narrow = require('./react/app/banner_narrow2.jpg');

    const ChangeMenu = (id) => {
        setOpen(false);
        switch (id) {
            case 0:
                navigate('/');
                break;
            case 1:
                navigate('/products');
                setLabel(lang.products_text);
                break;
            case 2:
                navigate('/gallery');
                setLabel(lang.gallery_text);
                break;
            case 3:
                navigate('/reference');
                setLabel(lang.reference_text);
                break;
            case 4:
                navigate('/contact');
                setLabel(lang.contact_text);
                break;

        }
    }

    const OpenMenu = () => {
        setOpen(true);
    }

    const CloseMenu = () => {
        setOpen(false);
    }

    return (
        <div style={{ width: '100%', height: 'auto', position: 'relative', justifyContent: 'center', alignItems: 'center' }}>

            <img src={props.index == 0 ? banner : banner_narrow} style={{ height: props.index == 0 ? isSmall ? 400 : 600 : isSmall ? 250 : 300, maxWidth: '100%', width: '100%', aspectRatio: 1.0 }} />

            <div style={{ ...styles.Block, height: isSmall ? menuHeightSmall : menuHeight, position: 'absolute', top: 0, backgroundColor: global.theme_menu, alignItems: 'center' }}>

                <div style={{ ...styles.BlockRow, flexDirection: 'row' }}>
                    {isSmall ?
                        <div style={{ ...styles.BlockLeft, width: 40 }}>
                            <IconButton onClick={() => OpenMenu()} style={{ height: 35 }}>
                                <FontAwesomeIcon style={{ marginRight: 15, color: global.theme_white }} icon={faBars} />
                            </IconButton>
                        </div>
                        : null}
                    {/* LOGO */}
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : 200, paddingLeft: isSmall ? 0 : global.padding, height: isSmall ? menuHeightSmall : menuHeight, justifyContent: 'center' }}>
                        <img onClick={() => ChangeMenu(0)} src={logo} height={isSmall ? 40 : 80} style={{ cursor: 'pointer' }} />
                    </div>
                    {isSmall ?
                        <div style={{ ...styles.BlockLeft, width: 40 }} />
                        : null}
                    {/* MENU BUTTONS */}
                    {isSmall == false ?
                        <div style={{ ...styles.BlockRight, flexDirection: 'row', paddingRight: global.padding, alignItems: 'center', height: menuHeight }}>
                            <Button onClick={() => ChangeMenu(0)} style={{ ...styles.Button, width: lang.lang_id == 2 ? 140 : 140 }}>
                                <p style={{ ...lang.lang_id == 2 ? styles.TextSmall : styles.TextLarge, color: props.index == 0 ? global.theme_light : global.theme_white }}>{lang.home.toUpperCase()}</p>
                            </Button>
                            <Button onClick={() => ChangeMenu(1)} style={{ ...styles.Button, width: lang.lang_id == 2 ? 140 : 140 }}>
                                <p style={{ ...lang.lang_id == 2 ? styles.TextSmall : styles.TextLarge, color: props.index == 1 ? global.theme_light : global.theme_white }}>{lang.products.toUpperCase()}</p>
                            </Button>
                            <Button onClick={() => ChangeMenu(2)} style={{ ...styles.Button, width: lang.lang_id == 2 ? 140 : 140 }}>
                                <p style={{ ...lang.lang_id == 2 ? styles.TextSmall : styles.TextLarge, color: props.index == 2 ? global.theme_light : global.theme_white }}>{lang.gallery.toUpperCase()}</p>
                            </Button>
                            <Button onClick={() => ChangeMenu(3)} style={{ ...styles.Button, width: lang.lang_id == 2 ? 140 : 140 }}>
                                <p style={{ ...lang.lang_id == 2 ? styles.TextSmall : styles.TextLarge, color: props.index == 3 ? global.theme_light : global.theme_white }}>{lang.reference.toUpperCase()}</p>
                            </Button>
                            <Button onClick={() => ChangeMenu(4)} style={{ ...styles.Button, width: lang.lang_id == 2 ? 220 : 140 }}>
                                <p style={{ ...lang.lang_id == 2 ? styles.TextSmall : styles.TextLarge, color: props.index == 4 ? global.theme_light : global.theme_white }}>{lang.contact.toUpperCase()}</p>
                            </Button>
                        </div>
                        :
                        // MENU SMALL
                        <Drawer
                            anchor={'left'}
                            open={isOpen}
                            onClose={CloseMenu}
                        >
                            <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_dark_blue, width: 200, height: '100%' }}>
                                <img src={logo} height={40} style={{ marginTop: 50 }} />
                                <Button onClick={() => ChangeMenu(0)} style={{ ...styles.Button, width: 140, marginTop: 50 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 0 ? global.theme_light : global.theme_white }}>{lang.home.toUpperCase()}</p>
                                </Button>
                                <Button onClick={() => ChangeMenu(1)} style={{ ...styles.Button, width: 140, marginTop: 5 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 1 ? global.theme_light : global.theme_white }}>{lang.products.toUpperCase()}</p>
                                </Button>
                                <Button onClick={() => ChangeMenu(2)} style={{ ...styles.Button, width: 140, marginTop: 5 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 2 ? global.theme_light : global.theme_white }}>{lang.gallery.toUpperCase()}</p>
                                </Button>
                                <Button onClick={() => ChangeMenu(3)} style={{ ...styles.Button, width: 140, marginTop: 5 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 3 ? global.theme_light : global.theme_white }}>{lang.reference.toUpperCase()}</p>
                                </Button>
                                <Button onClick={() => ChangeMenu(4)} style={{ ...styles.Button, width: 140, marginTop: 5 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 4 ? global.theme_light : global.theme_white }}>{lang.contact.toUpperCase()}</p>
                                </Button>
                            </div>
                        </Drawer>
                    }

                </div>
            </div>
            {props.index == 0 ?
                <div style={{ ...styles.Block, position: 'absolute', top: menuHeight, height: isSmall ? bodyHeightSmall : bodyHeight, justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ ...styles.TextXXLabel, color: global.theme_white, fontWeight: 'bold' }}>{lang.banner_label.toUpperCase()}</h1>
                    {isSmall == false ?
                        <div>
                            <p style={{ ...styles.TextLarge, color: global.theme_white, marginTop: 20 }}>{lang.years_business}</p>
                            <p style={{ ...styles.TextLarge, color: global.theme_white, marginTop: 20 }}>{lang.description}</p>
                        </div>

                        : null}
                    <Button onClick={() => ChangeMenu(1)} style={{ ...styles.ButtonMedium, width: 200, height: 50, marginTop: 40 }}>
                        {lang.more_info.toUpperCase()}
                    </Button>
                </div>
                :
                <div style={{ ...styles.Block, position: 'absolute', top: menuHeight, height: isSmall ? 80 : 160, justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ ...styles.TextLabel, color: global.theme_white }}>{label.toUpperCase()}</h1>
                </div>
            }
        </div >
    )

};


export const Loading = () => {
    /*
            USAGE:
            <Loading />
            */


    const logo = require('./react/app/logo.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <div style={{ ...styles.Block, width: 100, height: 100, backgroundColor: global.theme_white, borderRadius: 50 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_dark_green }} size={70} />
            </div>
        </Backdrop>
    );
}



export const BannerImage = (props) => {
    /*
            <BannerImage banner={banner} />
            */
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();

    var lang = sk;

    const Press = () => {
        if (props.banner.link != '') {
            navigate(props.banner.link);
        }
    }
    return (
        <div style={{ backgroundColor: global.theme_white, padding: 0, display: 'flex', flexDirection: 'row' }}>
            {isSmall == true ?
                <img onClick={() => Press()} src={global.images + 'banners/' + props.banner.image_small} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                :
                <img onClick={() => Press()} src={global.images + 'banners/' + props.banner.image} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
            }
        </div>
    )
}


export const BannerSlider = (props) => {
    /*
            <BannerSlider />
            */

    //BANNER: 2560 x 800 px
    //BANNER SMALL: 850 x 500 px

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const sliderSettings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 750,
    }

    const Slide = (props) => {
        const navigate = useNavigate();

        const BannerClick = (link) => {
            // kliknutie na banner
            if (link.trim() != '') {
                navigate(link);
            }
        }
        return (
            <div key={props.item.id} style={{ width: '100%', height: 'auto', position: 'relative' }}>
                {isSmall == true ?
                    <a onClick={() => BannerClick(props.item.link)} style={{ width: '100%', height: 'auto' }}>
                        <img src={global.images + '/banners/' + props.item.image_small} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        {props.item.text_position == 0 ?
                            // LEFT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: 0, top: 0, width: '40%', textAlign: 'left', paddingLeft: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label_small, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 10, fontSize: global.font_banner_text_small, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 1 ?
                            // CENTER ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: '25%', top: 0, width: '40%', textAlign: 'center', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label_small, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 10, fontSize: global.font_banner_text_small, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 2 ?
                            // RIGHT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', right: 0, top: 0, width: '40%', textAlign: 'right', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label_small, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 10, fontSize: global.font_banner_text_small, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                    </a>
                    :
                    <a onClick={() => BannerClick(props.item.link)} style={{ width: '100%', height: 'auto' }}>
                        <img src={global.images + '/banners/' + props.item.image} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        {props.item.text_position == 0 ?
                            // LEFT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: 0, top: 0, width: '40%', textAlign: 'left', paddingLeft: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 1 ?
                            // CENTER ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: '25%', top: 0, width: '40%', textAlign: 'center', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 2 ?
                            // RIGHT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', right: 0, top: 0, width: '40%', textAlign: 'right', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                    </a>
                }
            </div>)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 40 }}>
            <Slider  {...sliderSettings}>
                {props.banners.map(item => {
                    return (
                        <Slide key={item.id} item={item} />
                    )
                })}
            </Slider>
        </div>
    )
}

export const Footer = (props) => {
    /*
            <Footer language={language} />
            */

    const [year, setYear] = useState(2022);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(LoadLanguage());

    const navigate = useNavigate();

    const logo = require('./react/app/logo.png');
    const facebook = require('./react/app/facebook.png');
    const instagram = require('./react/app/instagram.png');
    const youtube = require('./react/app/youtube.png');

    const iconSize = 30;



    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', paddingTop: 30, backgroundColor: global.theme_footer }}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>

                {/* LAVA STRANA */}
                <div style={{ ...styles.BlockRight, justifyContent: 'flex-start' }}>
                    <img src={logo} height={isSmall ? 40 : 50} style={{ marginRight: 20 }} />
                </div>

                {/* PRAVA STRANA */}
                <div style={{ ...styles.BlockLeft, borderLeft: '1px solid ' + global.theme_dark_gray, paddingLeft: 20 }} >
                    <p style={{ ...isSmall ? styles.TextSmall : styles.TextNormal, color: global.theme_white, fontWeight: 'bold' }}>{global.company.name}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 10, textAlign: 'left' }}>{global.company.street}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}>{global.company.psc} {global.company.town}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}>{global.company.state}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 20, textAlign: 'left' }}>{lang.mobil_sk.toLowerCase()}: {global.company.mobil1}, {global.company.mobil2}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}>{lang.mobil_hu.toLowerCase()}: {global.company.mobil_hu}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 20, textAlign: 'left' }}>{lang.email.toLowerCase()}: {global.company.email}</p>
                </div>
            </div>

            {/* BOTTOM */}
            <div style={{ width: '100%', marginTop: 20, backgroundColor: global.theme_black }}>
                <div style={{ width: '100%', marginTop: 20, paddingTop: 20, paddingBottom: 50 }}>
                    <p style={{ ...styles.TextTiny, color: global.theme_medium_gray }}>{lang.cookies}</p>
                    <a href='https://datadream.sk' style={{ margin: 0, color: global.theme_medium_gray, fontSize: global.font_tiny, textDecoration: 'none' }}>© {lang.copyright} {year} - {global.creator}</a>
                </div>
            </div>
        </div >
    );
}

export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
        </div >
    );
}


export const GoHome = () => {
    window.scrollTo(0, 0);
}

export const Line = (props) => {
    return (
        <div style={{ width: 100, height: 2, backgroundColor: props.color != undefined ? props.color : global.theme_light, marginTop: 10, marginBottom: 10 }} />
    )
}


export const GalleryView = (props) => {
    /*

            USAGE:
            <GalleryView image={image} func={GalleryViewPress.bind(this)} />

            const GalleryViewPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [image, setImage] = useState('');
    const [imageCut, setImageCut] = useState('');
    const [index, setIndex] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    let { func } = props;

    useEffect(() => {

        setImage(props.image);
        setImageCut(props.imageCut);

        return () => {
        };

    }, []);

    const Press = (id) => {
        func(id);
    }


    return (
        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(true)}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                    <IconButton onClick={() => Press(true)} style={{ height: 40, width: 40, backgroundColor: global.theme_black, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faClose} />
                    </IconButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {image != '' ?
                        <div style={{ ...styles.Block }}>
                            <img src={global.mats + image} style={{ maxWidth: isSmall ? '100%' : 600, objectFit: 'contain' }} />
                            <img src={global.mats + imageCut} style={{ maxWidth: isSmall ? '100%' : 600, objectFit: 'contain' }} />
                        </div>
                        : null}
                </div>
            </div>
        </Dialog>
    )

};

