import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { sk, en } from './globals.js';
import { Main } from './main.js';
import { Contact } from './contact.js';
import { Products } from './products.js';
import { Product } from './product.js';
import { Gallery } from './gallery.js';
import { Reference } from './reference.js';
import { Footer, GetLanguage, LoadLanguage, MenuLogo, MenuTop } from './items';

function App() {
    const [language, setLanguage] = useState(0);
    const [showBanner, setShowBanner] = useState(true);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(language);

    useEffect(() => {
        setLanguage(LoadLanguage());
    }, [])

    const MenuTopPress = (value) => {
    }

    const MenuLogoPress = (value) => {
    }

    const MainPress = (value) => {
        setShowBanner(value);
    }

    return (
        <div className="App" style={{ backgroundColor: global.theme_white }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />

            <Helmet>
                <title>{global.title}</title>
                <meta name="description" content="Aluma" />
            </Helmet>

            <MenuTop lang={lang} func={MenuTopPress.bind(this)} />
            <MenuLogo lang={lang} index={showBanner} func={MenuLogoPress.bind(this)} />

            <Routes>
                <Route path="/" element={<Main func={MainPress.bind(this)} />} />
                <Route path="/products" element={<Products func={MainPress.bind(this)}/>} />
                <Route path="/gallery" element={<Gallery func={MainPress.bind(this)}/>} />
                <Route path="/reference" element={<Reference func={MainPress.bind(this)}/>} />
                <Route path="/contact" element={<Contact func={MainPress.bind(this)}/>} />
                <Route path="/product/:id" element={<Product func={MainPress.bind(this)}/>} />
            </Routes>

            <Footer />
        </div>
    );
}

export default App;
