import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { sk, en } from './globals.js';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, GetLanguage } from './items.js';
import { Block } from '@mui/icons-material';

export const Main = (props) => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState(0);
    const [showOrder, setShowOrder] = useState(false);
    const [isBusy, setBusy] = useState(false);

    //const step1 = require('./react/request.png');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const photo_1 = require('./react/app/photo_1.jpg');
    const photo_2 = require('./react/app/photo_2.jpg');
    const photo_3 = require('./react/app/photo_3.jpg');
    const photo_4 = require('./react/app/photo_4.jpg');

    const logo_kia = require('./react/app/logo_kia.png');
    const logo_orange = require('./react/app/logo_orange_bw.png');
    const logo_billa = require('./react/app/logo_billa_bw.png');
    const logo_tesco = require('./react/app/logo_tesco_bw.png');
    const logo_lidl = require('./react/app/logo_lidl_bw.png');
    const logo_metro = require('./react/app/logo_metro_bw.png');

    const banner = require('./react/app/banner2.jpg');

    var lang = GetLanguage(language);
    let { func } = props;


    useEffect(() => {
        func(0);
        setLanguage(LoadLanguage());
        GoHome();

        return () => {
        };

    }, []);

    const Mats = (id) => {
        navigate('/products');
        func(1);
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>

            {/* O NAS */}
            <div style={{ ...styles.Block, backgroundColor: global.theme_white }}>
                <div style={{ ...isSmall ? styles.BlockMaxSmall : styles.BlockMaxLarge, alignItems: 'center', marginTop: 50, marginBottom: 50 }}>
                    <h1 style={{ ...styles.TextLabel, marginTop: 10, textAlign: 'left' }}>{lang.about_us}</h1>
                    <Line />
                    <p style={{ ...styles.TextNormal, color: global.theme_dark_gray, textAlign: 'left' }}>{lang.about_us_text1}</p>
                    <p style={{ ...styles.TextNormal, color: global.theme_dark_gray, textAlign: 'left', marginTop: 15 }}>{lang.about_us_text2}</p>
                    <p style={{ ...styles.TextNormal, color: global.theme_dark_gray, textAlign: 'left', marginTop: 15 }}>{lang.about_us_text3}</p>
                    <p style={{ ...styles.TextNormal, color: global.theme_dark_gray, textAlign: 'left', marginTop: 15 }}>{lang.about_us_text4}</p>
                </div>
            </div>

            <div style={{ ...isSmall ? styles.BlockMaxSmall : styles.BlockMaxLarge, flexDirection: isSmall ? 'column' : 'row', marginTop: 50, marginBottom: 50 }}>
                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingRight: 10 }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_dark_gray, textAlign: 'left' }}>{lang.our_products}</p>
                    <h1 style={{ ...styles.TextXXLabel, marginTop: 10, textAlign: 'left' }}>{lang.mat_enter.toUpperCase()}</h1>
                    <Line />
                    <p style={{ ...styles.TextNormal, color: global.theme_black, marginTop: 0, textAlign: 'left' }}>{lang.mat_enter_text}</p>

                </div>
                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                    <img src={photo_1} width={'100%'}></img>
                </div>
            </div>

            {/* PRODUKTY - KATEGORIE */}
            <div style={{ ...styles.BlockMaxLarge, alignItems: 'center', marginTop: 50, marginBottom: 50 }}>
                <h1 style={{ ...styles.TextLabel, marginTop: 10, textAlign: 'left' }}>{lang.mat_catalogue}</h1>
                <Line />
                <p style={{ ...styles.TextSmall, color: global.theme_dark_gray, textAlign: 'left' }}>{lang.mat_catalogue_text}</p>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, justifyContent: 'center', marginTop: 20 }}>
                    <div onClick={() => Mats(0)} style={{ ...styles.Block, width: isSmall ? '80%' : '30%', marginLeft: 5, marginRight: 5, cursor: 'pointer' }}>
                        <img src={photo_2} width={'100%'} ></img>
                        <div style={{ ...styles.Block, backgroundColor: global.theme_light, height: 60 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.mat_ext}</p>
                        </div>
                    </div>
                    <div onClick={() => Mats(1)} style={{ ...styles.Block, width: isSmall ? '80%' : '30%', marginLeft: 5, marginRight: 5, cursor: 'pointer', marginTop: isSmall ? 50 : 0 }}>
                        <img src={photo_3} width={'100%'}></img>
                        <div style={{ ...styles.Block, backgroundColor: global.theme_light, height: 60 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.mat_int}</p>
                        </div>

                    </div>
                    {/*
                    <div onClick={() => Mats(2)} style={{ ...styles.Block, width: isSmall ? '80%' : '30%', marginLeft: 5, marginRight: 5, cursor: 'pointer', marginTop: isSmall ? 50 : 0 }}>
                        <img src={photo_4} width={'100%'}></img>
                        <div style={{ ...styles.Block, backgroundColor: global.theme_light, height: 60 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.mat_ind}</p>
                        </div>
                    </div>
                    */}
                </div>
            </div>

            {/* TOP KLIENTI */}
            <div style={{ ...styles.Block, alignItems: 'center', marginTop: 50, marginBottom: 0, backgroundColor: global.theme_white }}>
                <div style={{ ...styles.BlockMaxLarge, marginTop: 20, marginBottom: 80 }}>
                    <h1 style={{ ...styles.TextLabel, marginTop: 10, textAlign: 'left' }}>{lang.our_clients}</h1>
                    <Line />
                    <p style={{ ...styles.TextNormal }}>Kaufland, Strabag, Metrostav, Bauska, Ise, Stavmax BB, Slob-real, L-Construction, Truck Tatry, Domos Slovakia, Sytiq, Keraming, Mais</p>

                    {/*
                    <div style={{ ...styles.BlockRow, flexWrap: 'wrap', justifyContent: 'center', marginTop: 20 }}>
                        <div style={{ ...styles.Block, width: '15%', marginLeft: 20, marginRight: 20, cursor: 'pointer' }}>
                            <img src={logo_kia} width={'80%'} ></img>
                        </div>
                        <div style={{ ...styles.Block, width: '15%', marginLeft: 20, marginRight: 20, cursor: 'pointer' }}>
                            <img src={logo_orange} width={'80%'} ></img>
                        </div>
                        <div style={{ ...styles.Block, width: '15%', marginLeft: 20, marginRight: 20, cursor: 'pointer' }}>
                            <img src={logo_billa} width={'80%'} ></img>
                        </div>
                        <div style={{ ...styles.Block, width: '15%', marginLeft: 20, marginRight: 20, cursor: 'pointer' }}>
                            <img src={logo_tesco} width={'80%'} ></img>
                        </div>
                        <div style={{ ...styles.Block, width: '15%', marginLeft: 20, marginRight: 20, cursor: 'pointer' }}>
                            <img src={logo_lidl} width={'80%'} ></img>
                        </div>
                        <div style={{ ...styles.Block, width: '15%', marginLeft: 20, marginRight: 20, cursor: 'pointer' }}>
                            <img src={logo_metro} width={'80%'} ></img>
                        </div>
                    </div>
                     */}
                </div>
            </div>

            {/* BANNER */}
            <div style={{ ...styles.Block, alignItems: 'center', marginTop: 0, marginBottom: 0, backgroundColor: global.theme_white }}>
                <p style={{ ...isSmall ? styles.TextXXLarge : styles.TextLabel, color: global.theme_black, marginBottom: 30 }}>{lang.banner_text}</p>
                <Line />

                <img src={banner} width={'100%'} style={{ marginTop: 40 }}></img>
            </div>


        </div >
    )
}