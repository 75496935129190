import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { sk, en } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, GetLanguage } from './items.js';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { style } from '@mui/system';



export const Products = (props) => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState(LoadLanguage());
    const [items, setItems] = useState([]);
    const [over, setOver] = useState(-1);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 300;
    const boxHeight = boxWidth + 100;

    var lang = GetLanguage(LoadLanguage());
    let { func } = props;

    useEffect(() => {
        func(1);

        GoHome();

        var mats = lang.mats.filter(x => x.enabled == true);
        setItems(mats);

        return () => {
        };

    }, []);



    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>


            <div style={{ ...styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }}>
                <h1 style={{ ...styles.TextXXLabel, marginTop: 10, textAlign: 'left' }}>{lang.products.toUpperCase()}</h1>
                <Line />
            </div>

            <div style={{ ...styles.BlockMaxLarge }}>
                <div style={{ ...styles.BlockRow, flexWrap: 'wrap', alignItems:'center', justifyContent:'center' }}>
                    {items.map((item) => {
                        return (
                            <Paper key={item.id} elevation={over == item.id ? 5 : 0} style={{ width: boxWidth, height: boxHeight, backgroundColor: global.theme_gray, margin: 8, marginBottom: 30, cursor: 'pointer' }} onMouseEnter={() => setOver(item.id)} onMouseLeave={() => setOver(-1)}>
                                <div onClick={() => navigate('/product/' + item.id)} style={{ width: boxWidth, height: boxWidth }}>
                                    <img src={global.mats + item.image} style={{ width: '100%', height: '100%' }}></img>
                                </div>
                                <div style={{ ...styles.Block, height: boxHeight - boxWidth }}>
                                    <p style={{ ...styles.TextXXLarge, fontWeight: 'bold' }}>{item.title.toUpperCase()}</p>
                                    <p style={{ ...styles.TextSmall, marginTop: 5 }}>{item.description}</p>
                                </div>
                            </Paper>)
                    })}
                </div>
            </div>

        </div >
    )
}