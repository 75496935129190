import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { sk, en } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, GetLanguage } from './items.js';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';



export const Gallery = (props) => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState(0);
    const [itemData, setItemData] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/app/logo.png');

    var lang = GetLanguage(language);
    let { func } = props;

    useEffect(() => {
        func(2);

        setLanguage(LoadLanguage());
        GoHome();

        var n = 1;
        var data = [];
        while (n < 46) {
            data.push({ img: global.gallery + n.toString().padStart(3, '0') + '.jpg' });
            n++;
        }
        setItemData(data);

        return () => {
        };

    }, []);



    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>


            <div style={{ ...isSmall ? styles.BlockMaxSmall : styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }}>
                <h1 style={{ ...styles.TextXXLabel, marginTop: 10, textAlign: 'left' }}>{lang.gallery.toUpperCase()}</h1>
                <Line />
            </div>

            <div style={{ ...isSmall ? styles.BlockMaxSmall : styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }}>
                <ImageList sx={{ width: '100%', height: '100%' }} variant="masonry" cols={3} gap={8}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            <img
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                //srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={''}
                                loading="lazy"
                                style={{ margin: 0, padding: 0 }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </div >

        </div >
    )
}