import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { sk, en } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, GalleryView, GetLanguage } from './items.js';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { style } from '@mui/system';



export const Product = (props) => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState(LoadLanguage());
    const [item, setItem] = useState(null);
    const [subitem, setSubitem] = useState([]);
    const [subitem2, setSubitem2] = useState([]);
    const [over, setOver] = useState(-1);

    const [image, setImage] = useState('');
    const [imageCut, setImageCut] = useState('');
    const [showImage, setShowImage] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 300;
    const boxHeight = boxWidth + 100;

    var lang = GetLanguage(LoadLanguage());
    let { func } = props;
    let params = useParams();

    useEffect(() => {
        func(1);

        GoHome();

        var tmp = lang.mats.filter(x => x.id == params.id);


        if (tmp.length > 0) {
            setItem(tmp[0]);
            setSubitem(tmp[0].subitems);
            setSubitem2(tmp[0].subitems2);
        } else {
            navigate('/products');
        }

        return () => {
        };

    }, []);

    const ShowImage = (item) => {
        setImage(item.image);
        setImageCut(item.image_cut);
        setShowImage(true);
    }

    const GalleryViewPress = (value) => {
        setShowImage(false);
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>

            {item != null ?
                <div style={{ ...styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }}>
                    <h1 style={{ ...styles.TextXXLabel, marginTop: 10, textAlign: 'left' }}>{item.title.toUpperCase()}</h1>
                    <Line />
                </div>
                : null}

            {item != null ?
                <div style={{ ...styles.BlockMaxLarge, flexDirection: isSmall ? 'column' : 'row', justifyContent: 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', marginBottom: 50 }}>
                    <div style={{ ...styles.BlockCenter, width: boxWidth }}>
                        <Paper elevation={3} style={{ width: boxWidth, height: boxWidth, backgroundColor: global.theme_gray, margin: 8, marginBottom: 30, cursor: 'pointer' }} onMouseEnter={() => setOver(item.id)} onMouseLeave={() => setOver(-1)}>
                            <img src={global.mats + item.image} style={{ width: '100%', height: '100%' }}></img>
                        </Paper>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '90%', marginLeft: isSmall ? 0 : 20 }}>
                        {item.text1 != '' ?
                            <div style={{ marginTop: 10 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_1}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text1}</p>
                            </div>
                            : null}

                        {item.text2 != '' ?
                            <div style={{ marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_2}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text2}</p>
                            </div>
                            : null}

                        {item.text3 != '' ?
                            <div style={{ marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_3}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text3}</p>
                            </div>
                            : null}


                        {item.text4 != '' ?
                            <div style={{ marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_4}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text4}</p>
                            </div>
                            : null}


                        {item.text5 != '' ?
                            <div style={{ marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_5}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text5}</p>
                            </div>
                            : null}


                        {item.text6 != '' ?
                            <div style={{ marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_6}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text6}</p>
                            </div>
                            : null}


                        {item.text7 != '' ?
                            <div style={{ marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_7}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text7}</p>
                            </div>
                            : null}


                        {item.text8 != '' ?
                            <div style={{ marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>{lang.mat_data_8}</p>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 20, marginTop: 5 }}>{item.text8}</p>
                            </div>
                            : null}

                    </div>
                </div>
                : null}

            {subitem.length > 0 ?
                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 40, paddingBottom: 40 }}>
                    <div style={{ ...styles.BlockMaxLarge }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...styles.TextXXLarge, fontWeight: 'bold' }}>{lang.mat_ext}</p>
                        </div>

                        {subitem.map((item) => (
                            <div key={item.id} style={{ ...styles.Block, flexDirection: isSmall ? 'column' : 'row', marginTop: 40, marginBottom: 40 }}>
                                <div onClick={() => ShowImage(item)} style={{ ...styles.Block, width: isSmall ? '100%' : 300, cursor: 'pointer' }}>
                                    <img src={global.mats + item.image + '?11'} style={{ width: 300 }}></img>
                                    <img src={global.mats + item.image_cut + '?11'} style={{ width: 300 }}></img>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '90%', marginLeft: isSmall ? 0 : 20 }}>
                                    <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left' }}>{item.title}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left' }}>{item.note}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 10, textAlign: 'left' }}>{lang.mat_data_1}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_1}:</b> {item.text1}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_2}:</b> {item.text2}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_3}:</b> {item.text3}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_4}:</b> {item.text4}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_5}:</b> {item.text5}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_6}:</b> {item.text6}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_7}:</b> {item.text7}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_8}:</b> {item.text8}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                : null}

            {subitem2.length > 0 ?
                <div style={{ ...styles.Block, backgroundColor: global.theme_white, paddingTop: 40, paddingBottom: 40 }}>
                    <div style={{ ...styles.BlockMaxLarge }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...styles.TextXXLarge, fontWeight: 'bold' }}>{lang.mat_int}</p>
                        </div>

                        {subitem2.map((item) => (
                            <div key={item.id} style={{ ...styles.Block, flexDirection: isSmall ? 'column' : 'row', marginTop: 40, marginBottom: 40 }}>
                                <div onClick={() => ShowImage(item)} style={{ ...styles.Block, width: isSmall ? '100%' : 300, cursor: 'pointer' }}>
                                    <img src={global.mats + item.image + '?11'} style={{ width: 300 }}></img>
                                    <img src={global.mats + item.image_cut + '?11'} style={{ width: 300 }}></img>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '90%', marginLeft: isSmall ? 0 : 20 }}>
                                    <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left' }}>{item.title}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left' }}>{item.note}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 10, textAlign: 'left' }}>{lang.mat_data_1}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_1}:</b> {item.text1}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_2}:</b> {item.text2}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_3}:</b> {item.text3}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_4}:</b> {item.text4}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_5}:</b> {item.text5}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_6}:</b> {item.text6}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_7}:</b> {item.text7}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}><b>{lang.sub_mat_8}:</b> {item.text8}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                : null}

            {params.id == 0 ?
                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, marginTop: 0 }}>
                    <div style={{ ...styles.BlockMaxLarge, marginTop: 40, marginBottom: 40 }}>
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextXXLarge, fontWeight: 'bold' }}>{lang.alumat_ready}</p>
                            <div style={{ marginTop: 40 }}>
                                <img src={global.mats + 'alumat.png'} style={{ width: '100%', height: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            <div style={{ ...styles.Block, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.BlockMaxLarge, flexDirection: 'row', marginTop: 50, marginBottom: 50 }}>
                    <Button onClick={() => navigate('/products')} style={{ ...styles.ButtonDark }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 20, color: global.theme_white }} icon={faChevronLeft} />
                        {lang.back}
                    </Button>
                </div>
            </div>

            {showImage == true ?
                <GalleryView image={image} imageCut={imageCut} func={GalleryViewPress.bind(this)} />
                : null}

        </div >
    )
}